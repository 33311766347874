<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { useHomeStore } from '~~/stores/home'

const { isMobile } = useDevice()

const modules = [Navigation, Pagination, A11y, Autoplay]
const { addCdnToUrl } = useFormatUrlCdn()

const homeStore = useHomeStore()
const banners = computed(() => homeStore.state.banners)

onMounted(() => {
  homeStore.fetchBanners()
})
</script>

<template>
  <section id="section-banner" class="section-banner">
    <div class="container">
      <AtomsSkeleton v-if="banners.isLoading" height="inherit" />
      <AtomsEmpty
        v-else-if="!banners?.data?.length && !banners.isLoading"
        height="auto"
        description="Data banner tidak ditemukan"
      />
      <swiper
        v-show="banners?.data.length && !banners.isLoading"
        navigation
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false
        }"
        class="relative"
      >
        <template v-for="banner in banners?.data">
          <swiper-slide v-if="banner.isActive" :key="banner.serial">
            <a :href="banner.redirectURL" target="_blank">
              <nuxt-img
                class="banner-image"
                :src="addCdnToUrl(banner.imageURL)"
                alt="kognisi banner"
                title="kognisi banner"
                loading="lazy"
                size="100% sm:336px md:1136px"
                format="webp"
                :quality="isMobile ? '50' : '60'"
                preload
              />
            </a>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </section>
</template>

<style lang="scss">
@use './styles.scss';
</style>
